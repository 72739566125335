import React from "react"
import {
    BrowserRouter as Router,
    Route,
    Link

} from 'react-router-dom';

import { withRouter } from "react-router";

//country dialog
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ReactMarkdown from 'react-markdown'


class InfoModalWrapper extends React.Component {
    constructor(props) {
        super(props)
    }
    handleCountryDialogClose(){
        this.props.handleCountryDialogClose();
    }
    render() {
        return (
            <Dialog
                className={"info-modal"}
                open={this.props.isCountryDialogOpen}
                onClose={this.props.handleCountryDialogClose}
                aria-labelledby="scroll-dialog-title"
            >
            <button
            aria-label="Close"
            className="close-modal"
            onClick={this.handleCountryDialogClose.bind(this)}
            >
            <FontAwesomeIcon icon="times" />
            </button>
                <DialogTitle id="scroll-dialog-title">
               <span>{this.props.titulo} </span>
                </DialogTitle>
               
                <DialogContent>
                    
                    {this.props.mapaURL !== null && typeof this.props.mapaURL !== 'undefined' ?
                    <iframe
                        title={this.props.titulo + "-mapa"}
                        src={this.props.mapaURL}
                        width="100%" height="600"></iframe>
                    :
                    null}
                    {
                       typeof this.props.countryTrips !== 'undefined' && this.props.countryTrips !== null ?
                        <ReactMarkdown>{this.props.countryTrips.descripcion}</ReactMarkdown>
                        :
                        null
                    }
                </DialogContent>
            </Dialog>
        )
    }

}










const InfoModal = withRouter(InfoModalWrapper) ;
export default InfoModal;


