import React from "react"

import MainNav from "../gallery/mainNav"
import LinkToGallery from "../gallery/linkToGallery"
import InfoModal from "../gallery/infoModal"
import { Helmet } from "react-helmet";
class Home extends React.Component {


  render() {
    return (
      <div className={"home"}>
      <Helmet>
      <meta name="description" content={
        "Descubre rutas para tu viaje, día a día, en imágenes. Rutas de 2, 3 semanas o un mes por Tailandia, Laos, Vietnam..."
      } />
      </Helmet>
        <MainNav page="home" />

        {
          this.props.trips?.map((country, index) =>

            <LinkToGallery
              linkToGalleryHandler={this.props.linkToGalleryHandler}
              mapaURL={(typeof country[Object.keys(country)].frontmatter !== 'undefined') ?
                country[Object.keys(country)].frontmatter.mapaURL :
                null
              }
              key={`id-home-${Object.keys(country)[0].toLowerCase()}-${index}`}
              titulo={Object.keys(country)[0]}
              destinos={
                country[Object.keys(country)]["daysArray"].map((element) =>
                  element
                )
              }
              coverImages={country[Object.keys(country)]["coverImages"]}
              link={`${Object.keys(country)[0].toLocaleLowerCase()}`}
              isCountryDialogOpen={this.props.isCountryDialogOpen}
              handleCountryDialogClose={this.props.handleCountryDialogClose}
              handleCountryDialogOpen={this.props.handleCountryDialogOpen.bind(this)}
              countryTrips={country[Object.keys(country)[0].toLocaleLowerCase()]}
              isMobileOrPortrait={this.props.isMobileOrPortrait}

            >
{console.log(this.props)}
            </LinkToGallery>
          )
        }
        { 
          <InfoModal
            titulo={this.props.homepageTitulo}
            mapaURL={this.props.homepageMapaURL}
            countryTrips={this.props.homepageCountryTrips}
            isCountryDialogOpen={this.props.isCountryDialogOpen}
            handleCountryDialogClose={this.props.handleCountryDialogClose.bind(this)}
          >
          </InfoModal>
        }

      </div>
    )
  }

}



export default Home