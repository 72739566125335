import React from "react"

import Swipe from 'react-easy-swipe';

import {
    BrowserRouter as Router,
    Route,
    Link,

} from 'react-router-dom'
import { withRouter } from "react-router";

class SwipeGalleryWrapper extends React.Component {



    onSwipeImageHandler(direction, event) {
        const params = this.props.params;
        const subgallery = this.props.subgaleria;
        let selectedImageIndex= subgallery.findIndex((element, index)=>{
            return element.spotURL == params.infoSpot
         })

         if(selectedImageIndex===-1){//if no matching spot...
            selectedImageIndex = 0;
         }
        //this will update loading stage in App state
        this.props.subgaleria.map((element, index) => {
            if (direction === "forwards" && index === selectedImageIndex + 1) {
                this.props.hasComponentLoadedHandler("videoPlayer", false);
                this.props.hasComponentLoadedHandler("mainImage", false);
                let swipeURL = `/galeria/${params.destino}/${params.dia}/${subgallery[selectedImageIndex+1].spotURL}`;
                this.props.history.push(swipeURL);

                return this.props.onSwipeImageHandler(element, "forwards");
            } else if (direction === "backwards" && index === selectedImageIndex - 1) {
                this.props.hasComponentLoadedHandler("videoPlayer", false);
                this.props.hasComponentLoadedHandler("mainImage", false);
                let swipeURL = `/galeria/${params.destino}/${params.dia}/${subgallery[selectedImageIndex-1].spotURL}`;
                this.props.history.push(swipeURL);
                return this.props.onSwipeImageHandler(element, "backwards");
            }
        });
    }

    render() {
        return (
            <Swipe
                onSwipeLeft={this.onSwipeImageHandler.bind(this, "forwards")}
                onSwipeRight={this.onSwipeImageHandler.bind(this, "backwards")}
                onSwipeStart={this.props.touchScreenDetectionHandler}
                className={"image-wrapper"} >
                {this.props.children}
                {this.props.subgaleria.length > 1
                    ?
                    <div
                        className={`bullets`}>
                        {this.props.subgaleria.map((element, index) =>
                        
                            <span
                                key={"bullet-" + element.id}
                                id={element.id}
                                className={
                                  (
                                      ((this.props.params.infoSpot === 'info' || typeof this.props.params.infoSpot === 'undefined') && index === 0) 
                                      ||
                                      (this.props.params.infoSpot === element.spotURL)
                                      
                                      ?
                                    "active"
                                    :
                                    null)
                                }
                            >
                            </span>
                        )}
                    </div>
                    :
                    null}


            </Swipe>

        )
    }
}


 const SwipeGallery = withRouter(SwipeGalleryWrapper) ;
 export default SwipeGallery;