const createTripsObject =  () => {
  //IMPORTS
  function importAllFiles(r) {
      return r.keys().map(r);
  }

  //--IMAGES/VIDEOS/AUDIOS
  const imagesVideosAudios = importAllFiles(require.context('../images/destinos', true, /\.(png|jpe?g|svg|mp4|mp3)$/i));
  //debugger
  const getMatchingMarkdown = function (markdownFiles, location, isCountryDescription) {
    
    return (!isCountryDescription)
      ?
      markdownFiles.find(markdown => markdown.lastIndexOf(location) !== -1)
      :
      markdownFiles.find(markdown => markdown.toString().toLowerCase().lastIndexOf(location + ".") !== -1)
  }

  const getMatchingCountryImage = (imagesVideosAudios, location) => {
    //console.log("test")

    //console.log(location)
      const matchingCountryImage = imagesVideosAudios.find(function (media) {
        // //console.log(media)
        // //console.log(typeof media)


        if(typeof media !== 'undefined' && typeof media !== 'string'){
          //console.log("media--")
          let mediaLowerCase = media.src.toString().toLowerCase();
          //console.log('******')
          //console.log(mediaLowerCase.indexOf(location + '-width') !== -1)
          return mediaLowerCase.indexOf(location + '-width') !== -1;
        }
      });
      //console.log(matchingCountryImage)
      //console.log("...........")
      //console.log(matchingCountryImage)
      return matchingCountryImage;
  }

  //imagenes

  //counter
  let jumpDayCounter = 0;
  let subGalleryImageCounter = 0;
  let COUNTRIES = []
  let DAYS = [];
  let country;
  let countryNumber = 0;
  let previousCountry = false;
  let previousArea = false;
  let dayInArea = 0;

  //markdown files
  //--MARKDOWN FILES

  //frontmatter
  const markdownFiles = importAllFiles(require.context('../images/destinos', true, /\.md/));

  imagesVideosAudios.forEach((mediaFile, index, array) => {
    //console.log("IMAGES VIDEOS AUDIOS:")
    //console.log(array)
    let fechaAnterior, audioMatchingImage, previousImage;
    if (index !== 0) {
      previousImage = array[index - 1].toString();
    }

  

    //MARKDOWN VARIABLES
    let matchingMarkdown,
      matchingAreaMarkdown,
      matchingCountryDescription,
      mediaFileString = mediaFile.toString();
      //debugger
    let countryString = mediaFileString.substring(
      mediaFileString.lastIndexOf("destinos/") + 9 ,
    );
    country = (countryString.substring(0, countryString.indexOf("/"))).toString().toLowerCase();
      //debugger
    let isAudio = mediaFileString.indexOf("mp3") !== - 1 || mediaFileString.indexOf("MP3") !== - 1;
    let isVideo = mediaFileString.indexOf("mp4") !== - 1 || mediaFileString.indexOf("MP4") !== - 1;

    let isMainImage = mediaFileString.toLowerCase().indexOf(country + "-width-") !== - 1;

    if (isAudio) {
      const audioName = mediaFileString.substring(
        mediaFileString.lastIndexOf("_") + 1,
        mediaFileString.lastIndexOf(".")
      );
      audioMatchingImage = mediaFileString.substring(
        mediaFileString.lastIndexOf("/") + 1,
        mediaFileString.lastIndexOf("_")
      );

    }
    //evita error si es la primera imagen de todas
    if (index === 0) {
      previousImage = array[0].toString();

      //first country is pushed    
      matchingCountryDescription = getMatchingMarkdown(markdownFiles, country, true);

      //console.log("COUNTRYIMAGE:")
      //console.log(matchingCountryDescription)
     
      const countryImage = getMatchingCountryImage(imagesVideosAudios, country);
      //console.log(countryImage)
      if(typeof countryImage !== 'undefined'){
        COUNTRIES.push({
          [country]: {
            "descripcion": (typeof matchingCountryDescription !== 'undefined') ? matchingCountryDescription : null,
            "coverImages": countryImage.images,
            "daysArray": []
          }
        }); 
      }
    }

    //fecha actual
    let fecha1 = mediaFileString.substring(
      mediaFileString.lastIndexOf("media/") + 6,
      mediaFileString.indexOf("_")
    );
    let fecha = fecha1.substring(fecha1.lastIndexOf("/") + 1)


    //area 
    let area = mediaFileString.substring(
      mediaFileString.indexOf("_") + 1,
      mediaFileString.lastIndexOf("/")
    );

    //spot
    let mediaFileStringName = mediaFileString.substring(
      mediaFileString.lastIndexOf("/") + 1,
      mediaFileString.lastIndexOf(isVideo?".":"-width")
    );


    let mediaFileStringNameLower = mediaFileStringName.toString().toLowerCase();
    let spot;
    let type;
    if (mediaFileStringNameLower.indexOf("0_") !== -1) {
      mediaFileStringName = mediaFileStringName.split("0_")[1];
    }
    if (mediaFileStringNameLower.indexOf("top_") !== -1) {
      spot = mediaFileStringName.split("top_")[1] || mediaFileStringName.split("Top_")[1];
      type = "top";
    } else if (mediaFileStringNameLower.indexOf("comida_") !== -1) {
      spot = mediaFileStringName.split("comida_")[1] || mediaFileStringName.split("Comida_")[1];
      type = "comida";
    } else {
      spot = mediaFileStringName;
      type = "location"
    }



    //CREATING DATA OBJECT
    if (previousImage) {
      //console.log("PREVIOUS IMAGE")
      //extraer siguiente fecha
      let fechaAnterior1 = previousImage.substring(
        previousImage.lastIndexOf("media/") + 6,
        previousImage.indexOf("_")
      );
      fechaAnterior = fechaAnterior1.substring(fechaAnterior1.lastIndexOf("/") + 1)
      
      //countries
      previousCountry = (previousImage.substring(
        previousImage.lastIndexOf("destinos/") + 9,
      )).toString().toLowerCase();
      previousCountry = previousCountry.substring(0, previousCountry.indexOf("/"));  
      if (country !== previousCountry) {
        //debugger

        matchingCountryDescription = getMatchingMarkdown(markdownFiles, country, true);
        const countryImage = getMatchingCountryImage(imagesVideosAudios, country);

        //other countries are pushed here
        COUNTRIES.push({
          [country]: {
            //"frontmatter":content,
            "descripcion": (typeof matchingCountryDescription !== 'undefined') ? matchingCountryDescription : null,
            "coverImages": countryImage.images,
            "daysArray": []
          }
        })
        countryNumber += 1;
        jumpDayCounter = 0;
      }

   
      //console.log("´´´´´´´´´´´´´´´´´´´´´´")
      //console.log(COUNTRIES)
      //console.log(index)
      //console.log(countryNumber)
      DAYS = index === 0 ? COUNTRIES[0][country].daysArray : COUNTRIES[countryNumber][country].daysArray;

      if (!isMainImage) {
        //comprobar si siguiente foto pertenece al mismo dia
        if (fecha === fechaAnterior && index > 0 && country == previousCountry) { //si pertenece al mismo día añadir nueva imagen y localización a "subgaleria"
          subGalleryImageCounter += 1;
          //markdown
          matchingMarkdown = getMatchingMarkdown(markdownFiles, spot);
          if (!isAudio) {
            DAYS[jumpDayCounter - 1].subgaleria.push(
              {
                "spot": spot.replace(/-/g, " "),
                "spotURL":spot.replace(/ /g, "-"),
                "type": type,
                "info": (typeof matchingMarkdown !== 'undefined') ? matchingMarkdown : null,
                "src": mediaFileString,
                "responsiveImageProperties":isVideo ? null : mediaFile,
                "id": `id-${jumpDayCounter}-${subGalleryImageCounter}`,
                "audioSRC": null,
                "audioName": null,
                "mediaType": isVideo ? "video" : "image"
              })
          } else if (isAudio) {
            DAYS[jumpDayCounter - 1].subgaleria.map((element) => {
              if (element.spot === audioMatchingImage) {
                element.audioSRC = mediaFileString;
                element.audioName = audioMatchingImage;
                element.mediaType = "imageAudio"
              }
            }
            )
          }

        } else { //si no pertenece al mismo día push nuevo día
          jumpDayCounter += 1;
          subGalleryImageCounter = 0;

          //areas
          if(jumpDayCounter !== 1){
            previousArea = (previousImage.substring(
              previousImage.lastIndexOf(fechaAnterior) + 9,
            )).toString().toLowerCase();
            previousArea = previousArea.substring(0, previousArea.indexOf("/"));
        
          }
         
          if(area.toLowerCase()===previousArea){
            dayInArea += 1;
          } else{
            dayInArea = 0;
          }
          //markdown
          matchingAreaMarkdown = getMatchingMarkdown(markdownFiles, area);
          matchingMarkdown = getMatchingMarkdown(markdownFiles, spot);
     
          DAYS.push({
            "dia": jumpDayCounter,
            "prevArea": previousArea,
            "info": (typeof matchingAreaMarkdown !== 'undefined') ? matchingAreaMarkdown : "",
            "fecha": fecha,
            "area": area.replace(/-/g, " "),
            "dayInArea": dayInArea,
            "spot": spot.replace(/-/g, " "),
            "type": type,
            "imagenPrincipal": mediaFileString,
            "subgaleria": [{
              "spot":  spot.replace(/-/g, " "),
              "spotURL":spot.replace(/ /g, "-"),
              "type": type,
              "info": (typeof matchingMarkdown !== 'undefined') ? matchingMarkdown : null,
              "src": mediaFileString,
              "responsiveImageProperties":isVideo ? null : mediaFile,
              "id": `id-${jumpDayCounter}-${subGalleryImageCounter}`,
              "audioSRC": null,
              "audioName": null,
              "mediaType":isVideo ? "video" : "image"
            }]
          });
        }
      }
    }
  });

  //console.log(COUNTRIES)
  //createSitemap(COUNTRIES)
  return COUNTRIES;
}

export default createTripsObject;
