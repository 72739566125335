import React from "react"
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from 'react-router-dom'

import Thumbnail from './thumbnail'


class SubGaleria extends React.Component {
    constructor(props) {
        super(props)
    }

    subGaleriaClickHandler(id) {
        let index,
            isExtraImageSelected, selectedImageID;

        index = id.substring(
            id.lastIndexOf("-") + 1,
            id.length
        );
        selectedImageID = id;

        (index === 0) ? isExtraImageSelected = false : isExtraImageSelected = true;
        this.props.subGaleriaClickHandler(index, isExtraImageSelected, selectedImageID);
    }

    render() {
        return (
            <section id="sub-galeria">
                <ul>
                    {(
                    (this.props.subgaleria).map((element, index) =>
                        <li
                            key={element.id}
                            id={element.id}
                            onClick={this.subGaleriaClickHandler.bind(this, element.id)}
                            className={(((
                                this.props.spotActual.id === element.id && this.props.isExtraImageSelected)
                                ||
                                !this.props.isExtraImageSelected && this.props.spotActual.id === element.id
                            ))
                                ? "active"
                                : null}
                        >

                            <Link to={`/galeria/${this.props.destino}/${element.id.substring(
                                (element.id).lastIndexOf("id-") + 3,
                                (element.id).lastIndexOf("-"))}/${element.spotURL}${this.props.showSpotInfo ? "/info" : ""}`}>
                                {element.src.toLowerCase().indexOf("mp4") !== -1
                                    ?
                                    <Thumbnail videoSRC={element.src} />
                                    :
                                    <img
                                        src={element.src}
                                        alt={element.spot}
                                    ></img>
                                }
                            </Link>
                            
                        </li>
                        ))}
                </ul>
            </section>
        )
    }
}


export default SubGaleria;