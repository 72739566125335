import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';




const DestinationIntro = (props) =>{

  const [isShowing, setIsShowing] = useState(true);

  function toggle() {
    setIsShowing(!isShowing);
  }

        return(   
        <Collapse
        className={`destination-intro ${isShowing?'showing':''}`}
        in={isShowing}
        timeout={350}
        style={
          {
          "backgroundImage": "url(" + (props.isMobileOrPortrait?props.coverImages[0].path:props.coverImages[2].path) + ")",
          "backgroundPosition": "center center"
           }
          }
        >
        <div
        className="overlay"
        >
             <h1>{props.area}</h1>
            {/* <p>{props.info}</p>*/}
            {props.firstDayInCountry?
             <p>¡Comenzamos la aventura en <span className="country">{props.country}</span> desde {props.area}!</p>
              :
              <p>Continuamos el viaje por <span className="country">{props.country}</span>, parando unos días en {props.area}...</p>
          }
          {props.firstDayInCountry?
             <button onClick={toggle}>Comenzar el viaje</button>
              :
              <button onClick={toggle}>Descubrir <span className="country">{props.area} </span></button>
            }
        </div>
         </Collapse>
      )
    
}



export default DestinationIntro;
    
    


