import React from "react"
import { Player, ControlBar, PlayToggle} from 'video-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MediaControls extends React.Component {
  
   
    render() {
        
        return (
            <div className="media-controls">
                {this.props.title?
                <h5>{this.props.title}
                </h5>
                :
                null
                }
              
                 <button
                 className={"button-style-text"}
                 onClick={this.props.pauseButtonHandler}>
                    {this.props.paused?
                        <span style={{"opacity":"0.7"}}>
                           {this.props.paused} autoplay <b>OFF</b>
                        </span>
                        :
                        <span>
                          autoplay <b>ON</b>
                    </span>
                    
                    }
                 </button>
               
                <button
                className={"button-style-text audio-btn"}
                onClick={this.props.muteButtonHandler}>
                     {this.props.muted?
                    <span  style={{"opacity":"0.7"}}>
                        
                    <FontAwesomeIcon icon={"volume-off"}
                   ></FontAwesomeIcon>
                   <span>
                            &nbsp;audio <b>OFF</b>
                        </span>
                    </span>

                    :
                    <span>
                        
                    <FontAwesomeIcon icon={"volume-up"}>ON</FontAwesomeIcon>
                    <span>
                            &nbsp;audio <b>ON</b>
                        </span>
                    </span>


                     }
                </button>
            </div>
        )
    }




}

export default MediaControls;