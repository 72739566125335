import React from "react";

import MainNav from "../gallery/mainNav";
import LinkToGallery from "../gallery/linkToGallery";
import InfoModal from "../gallery/infoModal";
import { Helmet } from "react-helmet";
class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className={"privacy"}>
        <Helmet>
          <meta
            name="description"
            content={
              "Descubre rutas para tu viaje, día a día, en imágenes. Rutas de 2, 3 semanas o un mes por Tailandia, Laos, Vietnam..."
            }
          />
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
        <h1>Privacy Policy</h1>

        <h2>Política de privacidad de DayByDayPics</h2>
<p>Su privacidad es importante para nosotros. La política de DayByDayPics es respetar su privacidad y cumplir con todas las leyes y normas de aplicación con respecto a cualquier información personal que podamos recopilar sobre usted, incluido a través de nuestra aplicación, DayByDayPics, y sus servicios asociados. </p>
<p>Información personal es cualquier información sobre usted que se pueda usar para identificarle. Esto incluye información sobre usted como persona (como nombre, dirección y fecha de nacimiento), sus dispositivos, datos de pago e incluso información sobre cómo usa una aplicación o servicio online. </p>
<p>En caso de que nuestra aplicación contenga enlaces a sitios y servicios de terceros, tenga en cuenta que esos sitios y servicios tienen sus propias políticas de privacidad. Después de seguir un enlace a cualquier contenido de terceros, debe leer la información publicada de su política de privacidad sobre cómo recopilan y utilizan la información personal. Esta Política de privacidad no es aplicable a ninguna de sus actividades después de salir de nuestra aplicación. </p>
<p>Esta política entra en vigor el 13 de mayo de 2023. </p>
<p>Última actualización: 13 de mayo de 2023 </p>
<h3>Información que recopilamos</h3>
<p>La información que recopilamos se clasifica en una de estas dos categorías: información &ldquo;proporcionada voluntariamente&rdquo; e información &ldquo;recopilada automáticamente&rdquo;. </p>
<p>Información &ldquo;proporcionada voluntariamente&rdquo; se refiere a cualquier información que usted nos proporcione de forma consciente y activa al utilizar nuestra aplicación y sus servicios asociados. </p>
<p>Información &ldquo;recopilada automáticamente&rdquo; se refiere a cualquier información enviada automáticamente por su dispositivo durante su acceso a nuestra aplicación y sus servicios asociados. </p>
<h4>Información personal</h4>
<p>Podemos solicitar información personal — por ejemplo, cuando nos envía contenido o cuando se pone en contacto con nosotros — que puede incluir uno o más de los siguientes datos: </p>
<ul>
   <li>Nombre</li>
   <li>Correo electrónico</li>
</ul>
<h4>Contenido generado por los usuarios</h4>
<p>Consideramos que el &ldquo;contenido generado por los usuarios&rdquo; incluye materiales (texto, imágenes y/o contenido de video) proporcionados voluntariamente por nuestros usuarios con el fin de que se publiquen en nuestra plataforma, sitio web o para que se vuelvan a publicar en nuestros canales de redes sociales. Todo el contenido generado por los usuarios está vinculado a la cuenta o la dirección de correo electrónico utilizada para enviar los materiales. </p>
<p>Tenga en cuenta que cualquier contenido que envíe con el fin de que se publique será público después de la publicación (y su posterior revisión o proceso de evaluación). Una vez publicado, puede ser accesible para terceros no cubiertos por esta Política de privacidad. </p>
<h4>Razones legítimas para procesar su información personal</h4>
<p>Solo recopilamos y usamos su información personal cuando tenemos una razón legítima para hacerlo. En cuyo caso, solo recopilamos información personal que sea razonablemente necesaria para prestarle nuestros servicios. </p>
<h4>Recopilación y uso de información</h4>
<p>Podemos recopilar información personal proporcionada por usted cuando realiza cualquiera de las siguientes acciones en nuestro sitio web: </p>
<ul>
   <li>Registrarse para crear una cuenta</li>
   <li>Utilizar un dispositivo móvil o un navegador web para acceder a nuestro contenido</li>
   <li>Ponerse en contacto con nosotros por correo electrónico, redes sociales o cualquier tecnología similar</li>
   <li>Cuando nos menciona en redes sociales</li>
</ul>
<p>Podemos recopilar, retener, usar y divulgar información con los siguientes fines, y la información personal no se procesará posteriormente de ninguna manera que sea incompatible con estos fines: </p>
<ul>
   <li>proporcionarle las funciones y servicios principales de nuestra aplicación y plataforma</li>
   <li>permitirle acceder a y utilizar nuestra aplicación, plataformas asociadas y canales de redes sociales asociados</li>
</ul>
<p>Podemos combinar información personal proporcionada voluntariamente y recopilada automáticamente con información general o datos de investigación que recibimos de otras fuentes fiables. Por ejemplo, si da su consentimiento para que accedamos a sus perfiles de redes sociales, podemos combinar la información extraída de esos perfiles con la información facilitada por usted directamente para ofrecerle una experiencia mejorada de nuestra aplicación y nuestros servicios. </p>
<h4>Seguridad de su información personal</h4>
<p>Cuando recopilemos y procesemos información personal, y mientras conservemos esta información, la protegeremos utilizando medios comercialmente aceptables para evitar pérdidas y robos, así como el acceso, divulgación, copia, uso o modificación no autorizados. </p>
<p>Aunque haremos todo lo posible para proteger la información personal que nos proporcione, le informamos que ningún método de transmisión o almacenamiento electrónico es 100 % seguro y nadie puede garantizar la seguridad absoluta de los datos. </p>
<p>Usted es responsable de seleccionar la contraseña y su nivel de seguridad general, de la que dependerá la seguridad de su propia información dentro de los límites de nuestros servicios. Por ejemplo, deberá asegurarse de que las contraseñas asociadas con el acceso a su información personal y a sus cuentas sean seguras y confidenciales. </p>
<h4>Durante cuánto tiempo conservamos su información personal</h4>
<p>Conservamos su información personal solo durante el tiempo que sea necesario. Este periodo de tiempo puede depender de para qué estemos usando su información, de acuerdo con esta Política de privacidad. Por ejemplo, si nos ha proporcionado información personal como parte de la creación de una cuenta con nosotros, podemos retener esta información mientras su cuenta exista en nuestro sistema. Si su información personal ya no es necesaria a tal fin, la eliminaremos o la haremos anónima eliminando todos los detalles que le identifiquen. </p>
<p>Sin embargo, podemos retener su información personal si la necesitamos para cumplir con una obligación legal, de contabilidad o de notificación, o con fines de archivo de interés público, de investigación científica o histórica o con fines estadísticos. </p>
<h3>Privacidad de los niños</h3>
<p>Ninguno de nuestros productos y servicios está dirigido directamente niños menores de 13 años, y no recopilamos a sabiendas información personal sobre niños menores de 13 años. </p>
<h3>Sus derechos y el control de su información personal</h3>
<p><strong>Su elección:</strong> al proporcionarnos información personal, usted comprende que recopilaremos, retendremos, usaremos y divulgaremos su información personal de acuerdo con esta Política de privacidad. No está obligado a proporcionarnos información personal; sin embargo, el no hacerlo puede afectar al uso que haga de nuestra aplicación o de los productos y/o servicios que se ofrecen en ella o a través de ella. </p>
<p><strong>Información de terceros:</strong> si recibimos información personal sobre usted de un tercero, la protegeremos tal como se establece en esta Política de privacidad. Si usted es un tercero que proporciona información personal sobre otra persona, declara y garantiza que tiene el consentimiento de dicha persona para proporcionarnos su información personal. </p>
<p><strong>Permiso de marketing:</strong> si previamente ha aceptado que usemos su información personal con fines de marketing directo, puede cambiar de opinión en cualquier momento poniéndose en contacto con nosotros utilizando los datos que se indican abajo. </p>
<p><strong>Acceso:</strong> puede solicitar detalles de la información personal que tenemos sobre usted. </p>
<p><strong>Corrección:</strong> si cree que la información que tenemos sobre usted está desactualizada o es incompleta, irrelevante o engañosa, póngase en contacto con nosotros utilizando los datos que se indican en esta Política de privacidad. Tomaremos medidas razonables para corregir cualquier información que se considere inexacta, incompleta, engañosa o desactualizada. </p>
<p><strong>No discriminación:</strong> no lo discriminaremos por ejercer cualquiera de sus derechos sobre su información personal. A menos que su información personal sea necesaria para ofrecerle un servicio u oferta en particular (por ejemplo, ofrecer un contenido concreto a su dispositivo), no le negaremos bienes o servicios ni le cobraremos precios o tarifas diferentes por bienes o servicios, incluido mediante la concesión de descuentos u otras ventajas, o la imposición de sanciones, ni le proporcionaremos un nivel o calidad diferente de bienes o servicios. </p>
<p><strong>Descarga de información personal:</strong> ponemos a su disposición un medio para que descargue la información personal que ha compartido a través de nuestra aplicación. Para obtener más información, póngase en contacto con nosotros. </p>
<p><strong>Notificación de filtraciones de datos:</strong> cumpliremos las leyes que nos sean de aplicación con respecto a cualquier filtración de datos. </p>
<p><strong>Quejas:</strong> si cree que hemos incumplido una ley de protección de datos relevante y desea presentar una queja, póngase en contacto con nosotros utilizando los datos que se indican abajo y facilítenos todos los detalles del supuesto incumplimiento. Investigaremos su queja de inmediato y le responderemos, por escrito, exponiendo el resultado de nuestra investigación y las medidas que tomaremos para tratar su queja. También tiene derecho a ponerse en contacto con un organismo regulador o una autoridad de protección de datos en relación a su queja. </p>
<p><strong>Cancelar suscripción:</strong> para cancelar la suscripción a nuestra base de datos de correo electrónico o dejar de recibir comunicaciones (incluidas las comunicaciones de marketing), póngase en contacto con nosotros utilizando los datos que se indican en esta Política de privacidad, o cancele la suscripción mediante las funciones de exclusión voluntaria incluidas en la comunicación. Es posible que necesitemos pedirle información específica para ayudarnos a confirmar su identidad. </p>
<h3>Transferencias comerciales</h3>
<p>Si nosotros o nuestros activos somos adquiridos, o en el caso improbable de que cesemos la actividad o vayamos a la quiebra, incluiremos datos, incluida su información personal, entre los activos transferidos a la parte que nos adquiera. Usted acepta que tales transferencias pueden ocurrir, y que cualquier parte que nos adquiera podrá, en la medida en que lo permita la ley aplicable, continuar usando su información personal de acuerdo con esta política, que deberá asumir como base para ejercer cualquier derecho de propiedad o uso que tengamos sobre dicha información. </p>
<h3>Límites de nuestra política</h3>
<p>Nuestra aplicación puede tener enlaces a sitios externos que no operamos nosotros. Tenga en cuenta que no tenemos ningún tipo de control sobre el contenido y las políticas de esos sitios, y no podemos aceptar responsabilidad u obligación alguna por sus respectivas prácticas de privacidad. </p>
<h3>Cambios a esta Política</h3>
<p>A nuestra discreción, podemos cambiar nuestra Política de privacidad para reflejar actualizaciones de nuestros procesos comerciales, prácticas aceptables actuales o cambios legislativos o normativos. Si decidimos cambiar esta Política de privacidad, publicaremos los cambios aquí y en nuestro sitio web. </p>
<p>Si los cambios son significativos, o si así lo requiere la ley de aplicación, nos pondremos en contacto con usted (en función de sus preferencias seleccionadas para nuestras comunicaciones) y con todos nuestros usuarios registrados con los nuevos detalles y enlaces a la política actualizada o modificada. </p>
<p>Si así lo requiere la ley, obtendremos su permiso o le daremos la oportunidad de optar por participar u optar por no participar, según corresponda, en cualquier nuevo uso de su información personal. </p>
<h3>Divulgaciones adicionales para el cumplimiento del Reglamento General de Protección de Datos (RGPD) (UE)</h3>
<h4>Controlador de datos / Procesador de datos</h4>
<p>El RGPD distingue entre organizaciones que procesan información personal para sus propios fines (conocidas como &ldquo;controladores de datos&rdquo;) y organizaciones que procesan información personal en nombre de otras organizaciones (conocidas como &ldquo;procesadores de datos&rdquo;). Nosotros, DayByDayPics, situados en la dirección que se indica en nuestro epígrafe Contacto, somos un Controlador de datos con respecto a la información personal que usted nos proporciona. </p>
<h4>Fundamentos jurídicos para procesar su información personal</h4>
<p>Solo recopilaremos y usaremos su información personal cuando tengamos el derecho legítimo de hacerlo. En cuyo caso, recopilaremos y usaremos su información personal de forma lícita, justa y transparente. Si necesitamos su consentimiento para procesar su información personal y usted es menor de 16 años, solicitaremos el consentimiento de su padre, madre o tutor legal para procesar su información personal con ese fin específico. </p>
<p>Nuestros fundamentos jurídicos dependen de los servicios que utiliza y de cómo los utiliza. Esto significa que solo recopilamos y usamos su información por los siguientes motivos: </p>
<h5>Consentimiento dado por usted</h5>
<p>Cuando nos da su consentimiento para recopilar y utilizar su información personal con un fin específico. Puede retirar su consentimiento en cualquier momento utilizando las funciones que ofrecemos; sin embargo, esto no afectará al uso de su información que ya se haya realizado. Cuando se pone en contacto con nosotros, puede dar su consentimiento para que se utilice su nombre y dirección de correo electrónico para que podamos responder a su consulta. Si bien puede solicitar que eliminemos sus datos de contacto en cualquier momento, no podemos retirar ningún correo electrónico que ya hayamos enviado. Si tiene más preguntas acerca de cómo retirar su consentimiento, no dude en consultarnos utilizando los datos que se indican en el epígrafe Contacto de esta Política de privacidad. </p>
<h5>Ejecución de un contrato o transacción</h5>
<p>Cuando ha celebrado un contrato o transacción con nosotros, o para realizar los pasos preparatorios antes de que celebremos un contrato o transacción con usted. Por ejemplo, necesitamos información técnica sobre su dispositivo para proporcionar las funciones esenciales de nuestra aplicación. </p>
<h5>Nuestros intereses legítimos</h5>
<p>Cuando consideramos que es necesario para nuestros intereses legítimos, por ejemplo, para que proporcionemos, operemos, mejoremos y comuniquemos nuestros servicios. Por ejemplo, recopilamos información técnica sobre su dispositivo para mejorar y personalizar su experiencia con nuestra aplicación. Consideramos que nuestros intereses legítimos incluyen la investigación y el desarrollo, la comprensión de nuestro público, el marketing y la promoción de nuestros servicios, las medidas tomadas para operar nuestros servicios de manera eficiente, el análisis de marketing y las medidas tomadas para proteger nuestros derechos e intereses legales. </p>
<h5>Cumplimiento de la ley</h5>
<p>En algunos casos, es posible que tengamos la obligación legal de usar o conservar su información personal. Dichos casos pueden incluir (entre otros) órdenes judiciales, investigaciones penales, solicitudes gubernamentales y obligaciones reglamentarias. Si tiene alguna pregunta acerca de cómo retenemos la información personal para cumplir la ley, no dude en consultarnos utilizando los datos que se indican en el epígrafe Contacto de esta Política de privacidad. </p>
<h4>Transferencias internacionales fuera del Espacio Económico Europeo (EEE)</h4>
<p>Nos aseguraremos de que cualquier transferencia de información personal desde países del Espacio Económico Europeo (EEE) a países fuera del EEE esté protegida por las salvaguardias adecuadas, por ejemplo, mediante el uso de cláusulas estándar de protección de datos aprobadas por la Comisión Europea o el uso de normas corporativas vinculantes u otros medios legalmente aceptados. </p>
<h4>Sus derechos y el control de su información personal</h4>
<p><strong>Restringir:</strong> tiene derecho a solicitar que restrinjamos el procesamiento de su información personal si (i) le preocupa la exactitud de su información personal; (ii) cree que su información personal ha sido procesada ilegalmente; (iii) necesita que conservemos la información personal únicamente a efectos de una acción judicial; o (iv) estamos en proceso de considerar su objeción en relación con el procesamiento sobre la base de intereses legítimos. </p>
<p><strong>Oponerse al procesamiento:</strong> tiene derecho a oponerse al procesamiento de su información personal que se base en nuestros intereses legítimos o el interés público. Si se hace esto, debemos proporcionar motivos legítimos convincentes para el procesamiento que se antepongan a sus intereses, derechos y libertades, a fin de continuar con el procesamiento de su información personal. </p>
<p><strong>Portabilidad de datos:</strong> es posible que tenga derecho a solicitar una copia de la información personal que tenemos sobre usted. Siempre que sea posible, proporcionaremos esta información en formato CSV u otro formato legible fácilmente por máquina. También puede tener derecho a solicitar que transfiramos esta información personal a un tercero. </p>
<p><strong>Eliminación:</strong> es posible que tenga derecho a solicitar que eliminemos la información personal que tenemos sobre usted en cualquier momento, y tomaremos medidas razonables para eliminar su información personal de nuestros registros actuales. Si nos solicita que eliminemos su información personal, le informaremos de cómo la eliminación afecta a su uso de nuestra aplicación, sitio web o productos y servicios. Puede haber excepciones a este derecho por razones legales específicas que, si corresponde, le expondremos en respuesta a su solicitud. Si rescinde o elimina su cuenta, eliminaremos su información personal dentro de los 30 días posteriores a la eliminación de su cuenta. Tenga en cuenta que los motores de búsqueda y terceros similares aún pueden conservar copias de su información personal que se haya hecho pública al menos una vez, como cierta información de perfil y comentarios públicos, incluso después de que haya eliminado la información de nuestros servicios o desactivado su cuenta. </p>
<h3>Contacto</h3>
<p>Para cualquier duda o pregunta con respecto a su privacidad, puede ponerse en contacto con nosotros utilizando los siguientes datos: </p>
<p>Support<br />
daybydaypicsdevelopment@gmail.com </p>

      {
        /*
<p>Last updated: October 19, 2020</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the{" "}
          <a
            href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
            target="_blank"
          >
            Privacy Policy Generator
          </a>
          .
        </p>
        <h1>Interpretation and Definitions</h1>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Company</strong> (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Agreement) refers to sssss.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>Country</strong> refers to: Spain
            </p>
          </li>
          <li>
            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>Service</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used.
            </p>
          </li>
          <li>
            <p>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>Website</strong> refers to sssss, accessible from{" "}
              <a
                href="http://www.daybydaypics.com"
                rel="external nofollow noopener"
                target="_blank"
              >
                http://www.daybydaypics.com
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
            </p>
          </li>
        </ul>
        <h1>Collecting and Using Your Personal Data</h1>
        <h2>Types of Data Collected</h2>
        <h3>Personal Data</h3>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>Usage Data</p>
          </li>
        </ul>
        <h3>Usage Data</h3>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <h3>Tracking Technologies and Cookies</h3>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service. The technologies We use may
          include:
        </p>
        <ul>
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small
            file placed on Your Device. You can instruct Your browser to refuse
            all Cookies or to indicate when a Cookie is being sent. However, if
            You do not accept Cookies, You may not be able to use some parts of
            our Service. Unless you have adjusted Your browser setting so that
            it will refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Flash Cookies.</strong> Certain features of our Service may
            use local stored objects (or Flash Cookies) to collect and store
            information about Your preferences or Your activity on our Service.
            Flash Cookies are not managed by the same browser settings as those
            used for Browser Cookies. For more information on how You can delete
            Flash Cookies, please read &quot;Where can I change the settings for
            disabling, or deleting local shared objects?&quot; available at{" "}
            <a
              href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
            </a>
          </li>
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and
            our emails may contain small electronic files known as web beacons
            (also referred to as clear gifs, pixel tags, and single-pixel gifs)
            that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website
            statistics (for example, recording the popularity of a certain
            section and verifying system and server integrity).
          </li>
        </ul>
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on Your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close Your web browser. Learn more about cookies:{" "}
          <a
            href="https://www.freeprivacypolicy.com/blog/cookies/"
            target="_blank"
          >
            Cookies: What Do They Do?
          </a>
          .
        </p>
        <p>
          We use both Session and Persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
        </ul>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
        <h2>Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
          <li>
            <p>
              <strong>To provide and maintain our Service</strong>, including to
              monitor the usage of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </p>
          </li>
          <li>
            <p>
              <strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </p>
          </li>
          <li>
            <p>
              <strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </p>
          </li>
          <li>
            <p>
              <strong>For business transfers:</strong> We may use Your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of Our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by Us about our Service users is among
              the assets transferred.
            </p>
          </li>
          <li>
            <p>
              <strong>For other purposes</strong>: We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
          </li>
        </ul>
        <p>
          We may share Your personal information in the following situations:
        </p>
        <ul>
          <li>
            <strong>With Service Providers:</strong> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </li>
          <li>
            <strong>For business transfers:</strong> We may share or transfer
            Your personal information in connection with, or during negotiations
            of, any merger, sale of Company assets, financing, or acquisition of
            all or a portion of Our business to another company.
          </li>
          <li>
            <strong>With Affiliates:</strong> We may share Your information with
            Our affiliates, in which case we will require those affiliates to
            honor this Privacy Policy. Affiliates include Our parent company and
            any other subsidiaries, joint venture partners or other companies
            that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners:</strong> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </li>
          <li>
            <strong>With other users:</strong> when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside. If You interact with other users or
            register through a Third-Party Social Media Service, Your contacts
            on the Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
          </li>
          <li>
            <strong>With Your consent</strong>: We may disclose Your personal
            information for any other purpose with Your consent.
          </li>
        </ul>
        <h2>Retention of Your Personal Data</h2>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h2>Transfer of Your Personal Data</h2>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h2>Disclosure of Your Personal Data</h2>
        <h3>Business Transactions</h3>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h3>Law enforcement</h3>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h3>Other legal requirements</h3>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>
        <h2>Security of Your Personal Data</h2>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h1>
          Your California Privacy Rights (California's Shine the Light law)
        </h1>
        <p>
          Under California Civil Code Section 1798 (California's Shine the Light
          law), California residents with an established business relationship
          with us can request information once a year about sharing their
          Personal Data with third parties for the third parties' direct
          marketing purposes.
        </p>
        <p>
          If you'd like to request more information under the California Shine
          the Light law, and if You are a California resident, You can contact
          Us using the contact information provided below.
        </p>
        <h1>
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </h1>
        <p>
          California Business and Professions Code section 22581 allow
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </p>
        <p>
          To request removal of such data, and if You are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </p>
        <p>
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </p>
        <h1>Links to Other Websites</h1>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h1>Changes to this Privacy Policy</h1>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>By email: dbdpdevelopment@gmail.com</li>
        </ul>
        */
      }  
      </div>
    );
  }
}

export default PrivacyPolicy;
