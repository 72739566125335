import React from "react"
import {
    BrowserRouter as Router,
    Route,
    Link,
    Switch
} from 'react-router-dom'
import { withRouter } from "react-router";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfoModal from './infoModal'

//menu
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from "@material-ui/core/Grow";
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';



class MainNavWrapper extends React.Component {

    constructor(props) {
        super(props)
        this.linkToGalleryHandler = this.linkToGalleryHandler.bind(this);

    }
    handleCountryDropdownClose(e) {
        this.props.handleCountryDropdownClose(e, this.anchorEl)
    }

    linkToGalleryHandler(country) {
        this.props.history.push(`/galeria/${country}/1`)
        return this.props.linkToGalleryHandler(country);
    }

    render() {
        return (
            <nav id="main-nav" style={{
                display: "flex"
            }}>

                {this.props.page === 'home' ?
                    <div>
                        <Link to="/" className="logo">
                            <FontAwesomeIcon icon="home" /> <span>DayByDayPics</span>
                        </Link>
                    </div>
                    :
                    <div>
                        <Link to="/" className="logo">
                            <FontAwesomeIcon icon="home" /> <span>DayByDayPics</span>
                        </Link>
                        <Button
                            buttonRef={node => {
                                this.anchorEl = node;
                            }}
                            aria-owns={this.props.isCountryDropdownOpen ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={this.props.handleCountryDropdownToggle}
                        >
                            <FontAwesomeIcon className="hide-xs" icon="globe-asia" /> DESTINOS <FontAwesomeIcon icon="angle-down" />
                        </Button>
                        <Popper className={"dropdown"} open={this.props.isCountryDropdownOpen} anchorEl={this.anchorEl} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{
                                        transformOrigin: "center bottom"
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={this.handleCountryDropdownClose.bind(this)}>
                                            <MenuList>

                                                {
                                                    this.props.trips.map((country) =>
                                                        <MenuItem
                                                            key={`id-nav-${Object.keys(country)[0].toLowerCase()}`}
                                                            onClick={this.handleCountryDropdownClose.bind(this)}>
                                                            <Button
                                                                onClick={this.linkToGalleryHandler.bind(this, Object.keys(country)[0].toLowerCase())}
                                                            >{Object.keys(country)[0].toLowerCase()}
                                                            </Button>
                                                        </MenuItem>

                                                    )
                                                }
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <button onClick={this.props.handleCountryDialogOpen}>
                            <FontAwesomeIcon className="hide-xs" icon="info-circle" /> {this.props.country}
                        </button>
                        <InfoModal
                            mapaURL={typeof this.props.countryTrips.frontmatter !== 'undefined' ?
                                this.props.countryTrips.frontmatter.mapaURL :
                                null
                            }
                            titulo={this.props.country}
                            isCountryDialogOpen={this.props.isCountryDialogOpen}
                            handleCountryDialogClose={this.props.handleCountryDialogClose.bind(this)}
                            countryTrips={this.props.countryTrips}
                        >

                        </InfoModal>



                    </div>
                }
            </nav>
        )
    }

}








const MainNav = withRouter(MainNavWrapper) ;
export default MainNav;

