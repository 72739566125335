import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom'

import Galeria from './components/gallery/gallery'

import Home from './components/views/homeView'
import PrivacyPolicy from './components/views/privacyPolicy'
import AppUserCreated from './components/views/appUserCreated';
import Error404 from './components/views/error404'
import { Helmet } from "react-helmet";
import createTripsObject from './utils/createTrips'
import dbdpLogo from './assets/images/dbdp_logo.png';


//ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faLastfm } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCalendarDay, faInfo, faInfoCircle, faCamera, faVolumeUp, faVolumeOff, faStar, faGlobeAsia, faCity, faHome, faImages, faMapMarkerAlt, faAngleDown, faAngleRight, faAngleLeft, faUtensils, faTimes } from '@fortawesome/free-solid-svg-icons'
import { create } from 'jimp';
library.add(fab, faTimes, faCalendarDay, faInfo, faInfoCircle, faVolumeUp, faVolumeOff, faHome, faCamera, faStar, faUtensils, faGlobeAsia, faCheckSquare, faCity, faAngleDown, faAngleRight, faAngleLeft, faImages, faMapMarkerAlt)

//IMPORTS
function importAllFiles(r) {
  return r.keys().map(r);
}
//--IMAGES/VIDEOS/AUDIOS

const imagesVideosAudios = importAllFiles(require.context('./images/destinos', true, /\.(png|jpe?g|svg|mp4|mp3)$/i));

//--MARKDOWN FILES

//frontmatter

  var fm = require('front-matter')

const markdownFiles = importAllFiles(require.context('./images/destinos', true, /\.md/));
console.log("markdown")
console.log(markdownFiles)
class DiarioViajes extends React.Component {
  constructor(props, { match }) {

    super(props);
    const initialPathName = props.location.pathname;
   
    if (initialPathName.indexOf("galeria")) {
      const pathSection = initialPathName.substring(initialPathName.indexOf("/galeria/") + 9);
      this.currentCountry = pathSection.substring(0, pathSection.indexOf("/")).toString().toLowerCase();
    } else {
      this.currentCountry = "laos";
    }
    this.state = {
      navigatingForwards:true,
      currentDay: 1,
      initialPath: props.location.pathname,
      currentDestination: (props.location.pathname.substring(props.location.pathname.indexOf("/galeria/") + 9)).substring(0, (props.location.pathname.substring(props.location.pathname.indexOf("/galeria/") + 9)).indexOf("/")).toString().toLowerCase(),
      dropdownAnchorEl: null,
      isCountryDropdownOpen: false,
      isFull: false,
      showExtraInfo: false,
      isMobileOrPortrait: false,
      isTabletLandscape: false,
      showExtraFotos: false,
      showSpotInfo: false,
      isModalOpen: false,
      isCountryDialogOpen: false,
      isExtraImageSelected: false,
      selectedImageID: "id-1-0",
      selectedImageIndex: 0,
      touchScreen: false,
      muted: true,
      paused: false,
      windowInnerHeight: "100vh",
      componentsLoadingCompleted:{
        mainImage:false,
        videoPlayer:false,
      },
      extraInfoContentLoadingCompleted:false,

      homepageClickedInfo:{
          titulo:"",
          mapaURL:"",
          countryTrips:""
      }
    };

    //vieport dimensions
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.transformContent = this.transformContent.bind(this);
    this.updateTripsObjectContent = this.updateTripsObjectContent.bind(this);
  }
/* -------------LIFE CYCLE METHODS START--------------------------------------*/

  UNSAFE_componentWillMount() {
    this.TRIPS = createTripsObject();
    this.updateTripsObjectContent(this.TRIPS);
    //viewport dimensions
    window.removeEventListener('resize', this.updateWindowDimensions);

  }

  componentDidMount() {
    this.getCurrentCountry(this.props.location.pathname)  

    //viewport dimensions
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

/* -------------LIFE CYCLE METHODS END--------------------------------------*/

  //viewport dimensions
  updateWindowDimensions() {
      //set height to fix 100% height issue
      if (window.innerWidth < 800 && window.innerHeight > 400 || window.innerWidth < 800 && window.innerHeight < 400){
        document.body.style.height = window.innerHeight + "px"
      }else{
        document.body.style.height = "100%"
      }

      if (!this.state.isFull){
        this.setState({
          isMobileOrPortrait:(window.innerWidth <= 1024 && window.innerHeight > 1300) || (window.innerWidth < 800 && window.innerHeight > 400),
          isTabletLandscape: window.innerWidth < 800 && window.innerHeight < 400,
          showExtraInfo: (window.innerWidth <= 1024 && window.innerHeight > 1300) || (window.innerWidth < 800 && window.innerHeight > 400),
          muted: !((window.innerWidth <= 1024 && window.innerHeight > 1300) || (window.innerWidth < 800 && window.innerHeight > 400)),
          windowInnerHeight: window.innerWidth < 800 && window.innerHeight > 400? (window.innerHeight + "px") : this.state.windowInnerHeight
        });
      }  
  }

/* -------------CONTENT OBJ CREATION START--------------------------------------*/

  getCurrentCountry(path) {
    if (path.indexOf("galeria") !== -1) {
      const pathSection = path.substring(path.indexOf("/galeria/") + 9);
      this.setState({ currentDestination: pathSection.substring(0, pathSection.indexOf("/")).toString().toLowerCase() })
    } else {
      return false;
    }
  }


  transformContent(text, countryIndex, dayIndex, subgalleryIndex, tipoContenido, country, COUNTRIES) {   
    let countryObj = COUNTRIES[countryIndex][country];
    var frontmatterAndText = fm(text);
    let day = countryObj.daysArray[dayIndex];
    console.log("TIPO DE CONTENIDO")
    console.log(tipoContenido)
    //--------------DAY DESCRIPTIONS AND META    
    if (tipoContenido === "dayDescription") { 
      let dayInArea = day.dayInArea;
        
      day.frontmatter = frontmatterAndText.attributes 
      day.info = frontmatterAndText.body
      //avoid duplicate meta descriptions
      if(day.dayInArea !== 0){
        day.frontmatter.meta = null;
      }
      if(typeof day.frontmatter.infoDia !== 'undefined'){
        day.frontmatter.infoDia = day.frontmatter.infoDia[dayInArea];
      }

    } else if (tipoContenido === "subgaleria") {      
      day.subgaleria[subgalleryIndex].frontmatter = frontmatterAndText.attributes
      day.subgaleria[subgalleryIndex].info = frontmatterAndText.body
    } else if (tipoContenido == "countryDescription") {
      countryObj.frontmatter = frontmatterAndText.attributes
      countryObj.descripcion = frontmatterAndText.body
    }
      /*DEV ONLY
      if(frontmatterAndText.attributes.textoRevisado == "no" || frontmatterAndText.attributes.linksRevisados == "no"){
        console.log((frontmatterAndText.attributes.textoRevisado == "no")?"REVISAR TEXTO":""
        +(frontmatterAndText.attributes.linksRevisados
           == "no")?"REVISAR LINKS":""
        )
        console.log(frontmatterAndText.attributes.meta)
        console.log("---------------------")
      }
      */
  }

  updateTripsObjectContent(props){
    console.log()
    this.TRIPS.map((country, countryIndex) => {
      let countryKey = Object.keys(country)[0];
      //transform content for each countries day
      country[countryKey].daysArray.map((element, dayIndex) => {
        //map subgaleria y añadir info a cada foto
        element.subgaleria.map((elementoSub, subgalleryIndex) => {
          if (elementoSub !== null && typeof elementoSub !== 'undefined') {
   
            if (elementoSub.info !== null && !(typeof elementoSub.info === 'object')) {
           
              return fetch(elementoSub.info).then(
                response => { return response.text() }).then(
                  text => { this.transformContent(text, countryIndex, dayIndex, subgalleryIndex, "subgaleria", countryKey, this.TRIPS) }).then(
                    res=>this.setState({extraInfoContentLoadingCompleted:true})).catch(
                    err => { console.log(err) })
            }
            else {
              return '';
            }
          }
        })
      })

      country[countryKey].daysArray.map((element, dayIndex) => {
          return fetch(element.info).then(
            response => { return response.text() }).then(
              text => { this.transformContent(text, countryIndex, dayIndex, false, "dayDescription", countryKey, this.TRIPS) }).catch(
                err => { console.log(err) })
      })

      if (country[countryKey].descripcion !== null && typeof country[countryKey].descripcion !== 'undefined') {
        console.log("COUNTRY......")
        return fetch(country[countryKey].descripcion).then(
          response => { return response.text() }).then(
            text => { 
              this.transformContent(text, countryIndex, false, false, "countryDescription", countryKey, this.TRIPS)
            }).catch(
              err => { console.log(err) })
      }
      else {
        return '';
      }
    })
  }

/* -------------CONTENT OBJ CREATION END--------------------------------------*/

  //country dropdown
  handleCountryDropdownToggle = () => {
    this.setState({ isCountryDropdownOpen: !this.state.isCountryDropdownOpen });
  };

  handleCountryDropdownClose = (event, anchorEl) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ isCountryDropdownOpen: false });
  };

  //component loading stage handlers
  hasComponentLoadedHandler(componentName, isLoaded){
    this.setState({
      componentsLoadingCompleted: {
        [componentName]:isLoaded
      }
    })
  }

  //country link handler
  linkToGalleryHandler(destino) {
    this.setState({
      currentDestination: destino
    })
  }

  //media control handler
  pauseButtonHandler(e) {
    e.preventDefault(e);
    this.setState({
      paused: !this.state.paused
    })
  }

  muteButtonHandler(e) {
    e.preventDefault(e);
    this.setState({
      muted: !this.state.muted
    })
  }


  //boton extra info
  buttonExtraInfoClickHandler(e) {
    e.preventDefault()
    this.setState({
      showExtraInfo: this.state.isMobileOrPortrait ? true : !this.state.showExtraInfo,
      showSpotInfo: false
    })
  }

  //boton extra fotos
  buttonFotosClickHandler(e) {
    e.preventDefault()
    this.setState({
      showExtraFotos: !this.state.showExtraFotos,
      showExtraInfo: false
    })
  }

  //boton spot info
  buttonSpotClickHandler(e) {
    e.preventDefault()
    this.setState({
      showSpotInfo: this.state.isMobileOrPortrait ? true : !this.state.showSpotInfo,
      showExtraInfo: false
    })
  }

  //main image click
  mainImageClickHandler() {
    this.setState({
      isModalOpen: true
    })
  }

  //modal close
  handleModalClose() {
    this.setState({
      isModalOpen: false
    })
  }


  //country dialog close
  handleCountryDialogClose() {
    this.setState({
      isCountryDialogOpen: false
      
    })
  }

  //country dialog open
  handleCountryDialogOpen(countryTrips, mapaURL, titulo) {
    console.log("handleCountryDialogOpen(countryTrips, mapaURL, titulo)")
    console.log(countryTrips)
    this.setState({
      isCountryDialogOpen: true,
      homepageClickedInfo:(typeof countryTrips !== 'undefined'?
      {
        titulo:titulo,
        mapaURL:mapaURL,
        countryTrips:countryTrips
    }
    : this.state.homepageClickedInfo
      )
     
    }) 
  }

  //isExtraImageSelected to FALSE when route changes
 // routeChangeHandler() {
   //use this to update URL related STATE...
  //}

  dayChangeHandler(navigatingForwards){
    this.setState({
      navigatingForwards: navigatingForwards,
      isExtraImageSelected: false,
      selectedImageIndex: 0,
      showExtraInfo: this.state.isMobileOrPortrait?true:this.state.showExtraInfo,
      showSpotInfo: this.state.isMobileOrPortrait?false:this.state.showSpotInfo,
      componentsLoadingCompleted:{
        videoPlayer:false,
        mainImage:false
      }
    })
  }

  //spot URL handler 
  spotURLChangeHandler(selectedImageIndex){
    this.setState({
      selectedImageIndex: selectedImageIndex,
    })
  }

  //subgaleria click
  subGaleriaClickHandler(index, isExtraImageSelected, selectedImageID) {
    this.setState({
      isExtraImageSelected: isExtraImageSelected,
      selectedImageIndex: index,
      selectedImageID: selectedImageID,
      showExtraInfo: false,
      showSpotInfo: (this.state.showExtraInfo || this.state.showSpotInfo) ? true : false
    })
  }

  //swipe events
  touchScreenDetectionHandler() {
    this.setState({
      touchScreen: true
    })
  }

  onSwipeImageHandler(element, direction) {
    if(element === false){
      return null 
    }else{
      this.setState({
        isExtraImageSelected: this.state.selectedImageIndex > 0,
        selectedImageID: element.id,
        selectedImageIndex: (direction === "forwards" ? this.state.selectedImageIndex + 1 : this.state.selectedImageIndex - 1),
        showSpotInfo: false
      })
    }
  }
  
  //go full screen handler
  goFull(e, escPressed) {
    e.preventDefault()
    this.setState({ isFull: escPressed&&this.state.isFull?true:!this.state.isFull });
  }

  /* -------------HANDLERS END--------------------------------------*/

  render() {
    let countryTrips;
    //si es mayor que 0 o 1 es que está en ruta "galeria"
    if (this.state.currentDestination.length > 1) {
      const countryFilteredTrips = (this.TRIPS).filter(obj => {
        return obj.hasOwnProperty(this.state.currentDestination)
      })
      countryTrips = countryFilteredTrips[0][this.state.currentDestination];
    } else {
      countryTrips = null;
    }
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="robots" content="index, follow"></meta>
          <title>daybydaypics.com</title>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700,800" rel="stylesheet" />
        </Helmet>
        <Switch>
         
          <Route exact path="/" component={props =>
            <Home
              handleCountryDialogClose={this.handleCountryDialogClose.bind(this)}
              handleCountryDialogOpen={this.handleCountryDialogOpen.bind(this)}
              isCountryDialogOpen={this.state.isCountryDialogOpen}
              trips={this.TRIPS}
              linkToGalleryHandler={this.linkToGalleryHandler.bind(this)}
              homepageTitulo={this.state.homepageClickedInfo.titulo}
              homepageMapaURL={this.state.homepageClickedInfo.mapaURL}
              homepageCountryTrips={this.state.homepageClickedInfo.countryTrips}
              isMobileOrPortrait={this.state.isMobileOrPortrait}

              {...props} />} />

           
              <Route path="/politica-de-privacidad">
              <PrivacyPolicy></PrivacyPolicy>
              </Route>


              <Route path="/share/:permalink">
              <div style={{
                'display':'flex',
                'min-height': '100%',
                alignItems:'center',
                justifyContent:'center',
                flexFlow:'column',
                padding:'30px',
                textAlign:'center'
              }}>

           
                <img src={dbdpLogo} style={{'width':'100px'}}/>
             <h1>
               Abre el link con la app daybydaypics
             </h1>
             <p>Al abrir el link, el teléfono te preguntará si prefieres abrirlo con el navegador o la app. Si no ha sucedido, es posible que tengas que entrar en los ajustes de la app.</p>
             </div>
              </Route>
              share/tailandia-de-sur-a-norte-e-islas-NJiZ_SfvS
          <Route path="/galeria/:destino/:dia/:infoSpot?/:info?" render={props =>
            <Galeria
              windowInnerHeight={this.state.windowInnerHeight}
              componentsLoadingCompleted={this.state.componentsLoadingCompleted}
              hasComponentLoadedHandler={this.hasComponentLoadedHandler.bind(this)}
              isMobileOrPortrait={this.state.isMobileOrPortrait}
              isTabletLandscape={this.state.isTabletLandscape}
              linkToGalleryHandler={this.linkToGalleryHandler.bind(this)}
              currentDestination={this.state.currentDestination}
              trips={this.TRIPS}
              countryTrips={countryTrips}
              muted={this.state.muted}
              muteButtonHandler={this.muteButtonHandler.bind(this)}
              paused={this.state.paused}
              pauseButtonHandler={this.pauseButtonHandler.bind(this)}
              touchScreen={this.state.touchScreen}
              touchScreenDetectionHandler={this.touchScreenDetectionHandler.bind(this)}
              onSwipeImageHandler={this.onSwipeImageHandler.bind(this)}
              isFull={this.state.isFull}
              goFull={this.goFull.bind(this)}
              isModalOpen={this.state.isModalOpen}
              isCountryDialogOpen={this.state.isCountryDialogOpen}
              handleCountryDropdownToggle={this.handleCountryDropdownToggle.bind(this)}
              handleCountryDropdownClose={this.handleCountryDropdownClose.bind(this)}
              dropdownAnchorEl={this.state.dropdownAnchorEl}
              isCountryDropdownOpen={this.state.isCountryDropdownOpen}
              handleCountryDialogClose={this.handleCountryDialogClose.bind(this)}
              handleCountryDialogOpen={this.handleCountryDialogOpen.bind(this)}
              dayChangeHandler={this.dayChangeHandler.bind(this)}
              spotURLChangeHandler={this.spotURLChangeHandler.bind(this)}
              handleModalClose={this.handleModalClose.bind(this)}
              markdownFiles={markdownFiles}
              allImagesVideosAudios={imagesVideosAudios}
              navigatingForwards={this.state.navigatingForwards}
              isExtraImageSelected={this.state.isExtraImageSelected}
              selectedImageID={this.state.selectedImageID}
              buttonExtraInfoClickHandler={this.buttonExtraInfoClickHandler.bind(this)}
              buttonFotosClickHandler={this.buttonFotosClickHandler.bind(this)}
              buttonSpotClickHandler={this.buttonSpotClickHandler.bind(this)}
              mainImageClickHandler={this.mainImageClickHandler.bind(this)}
              subGaleriaClickHandler={this.subGaleriaClickHandler.bind(this)}
              selectedImageIndex={this.state.selectedImageIndex}
              showExtraInfo={this.state.showExtraInfo}
              showSpotInfo={this.state.showSpotInfo}
              showExtraFotos={this.state.showExtraFotos}
              {...props} />} />

            <Route path="/usuario-confirmado">
              <AppUserCreated></AppUserCreated>
              </Route>


          <Route render={
            ()=><Error404></Error404>
          } />
         
          {/*no quiero una ruta para subgaleria
       <Route path={`galeria/:dia/:subgaleria`} component={SubGaleria}/>
       */
          }
            </Switch>
      </div>
    )
  }
}

DiarioViajes.defaultProps = {
  'allImagesVideosAudios': imagesVideosAudios,
  'markdownFiles': markdownFiles
};

const reload = () => window.location.reload();

//https://stackoverflow.com/questions/45782571/serve-anotherstandalone-page-or-static-file-in-website-built-with-react
const DiarioRoute = (props) =>
  (
    <Router>
      <Route component={DiarioViajes} />

    </Router>
  )

export default DiarioRoute;
