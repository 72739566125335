import React from "react"
import { BrowserRouter as Route, Link } from "react-router-dom";
import { withRouter } from "react-router";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class LinkToGallery extends React.Component {

    constructor(props) {
        super(props)
        console.log("LINK TO GALLERY PROPS")
        console.log(props)
        this.linkToGalleryHandler = this.linkToGalleryHandler.bind(this);
    }

    linkToGalleryHandler(isCountryLink) {
        if (isCountryLink) {
            this.props.history.push(`/galeria/${this.props.link}/1`)
        }
        return this.props.linkToGalleryHandler(this.props.titulo.toLowerCase());
    }
    render() {
        const todosLosDestinos = this.props.destinos.map((element) => {
            return element.area.trim()
        })
        const listaDestinos = todosLosDestinos.reduce((accumulator, currentElement, currentIndex, array) => {

            if (currentIndex === 0 || currentElement !== array[currentIndex - 1]) {
                accumulator = accumulator.concat(
                    {
                        "destino": currentElement,
                        "indexDestino": currentIndex + 1

                    })
            }
            return accumulator;
        }, [])

        return (
            <section className="link-to-gallery" style={
                {
                    "backgroundImage": "url(" + (this.props.isMobileOrPortrait?this.props.coverImages[0].path:this.props.coverImages[2].path) + ")",
                    "backgroundPosition": "center center"
                }
            }>
                <div>
                    <h1>
                        <span>{todosLosDestinos.length} días en</span>
                        <span>{this.props.titulo}</span>
                        <button onClick={
                            this.props.handleCountryDialogOpen.bind(this, this.props.countryTrips, this.props.mapaURL, this.props.titulo)
                        }>
                            <FontAwesomeIcon icon="info-circle" /> {this.props.country}
                        </button>
                    </h1>

                    <ul>
                        <li>
                            <button
                                onClick={this.linkToGalleryHandler.bind(this, true)}
                            >Empezar el viaje
                            </button>
                        </li>
                        {
                            listaDestinos.map((element, index, array) =>
                                <li
                                    onClick={this.linkToGalleryHandler.bind(this, false)}

                                    key={`${this.props.titulo}-${index}`}>
                                    <span>
                                        <Link to={`galeria/${this.props.titulo}/${element.indexDestino}`} >

                                            {element.destino}
                                            <span className={"num-dias"}>

                                                {`  en
                                                
                                        ${(
                                                        index < array.length - 1
                                                            ?
                                                            (array[index + 1].indexDestino - element.indexDestino + (array[index + 1].indexDestino - element.indexDestino > 1 ? " días" : " día"))
                                                            :
                                                            (todosLosDestinos.length + 1) - element.indexDestino + ((todosLosDestinos.length + 1) - element.indexDestino > 1 ? " días" : " día")
                                                    )}
                                        
                                     `}
                                                <FontAwesomeIcon icon="angle-right" /><FontAwesomeIcon icon="angle-right" /> {this.props.country}

                                            </span>

                                        </Link></span> </li>
                            )
                        }
                    </ul>


                </div>
                
            </section>
        )
    }



}
//onClick={this.linkToGalleryHandler.bind(this)}

export default withRouter(LinkToGallery) 