import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';


const BotonExtraFotos = (props) => {
    return (

        <Button
            className={props.showExtraFotos ? "active" : null}
            id="boton-extra-fotos"
            onClick={props.buttonFotosClickHandler} >
            <FontAwesomeIcon icon="images" />
            <span>
             {" "}
             Fotos del día {props.showExtraFotos ? "-" : "+"}
            </span>
        </Button>
    )
}

export default BotonExtraFotos;




