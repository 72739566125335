import React from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';

import ReactMarkdown from 'react-markdown'


const SpotInfo = (props) =>{
    return(   
        <Fade
        in={true}
        timeout={{enter: 200, exit:  200}}
        >    
        <article id="spot-info">
        <Scrollbars  className={"scrollbar"} style={{ width: 'auto', height: 250 }}>
   
          <ReactMarkdown>{props.info}</ReactMarkdown>
          </Scrollbars>
        </article>    
        </Fade>
             
    )
}

export default SpotInfo;
    
    


