import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';


class mainImage extends React.Component {

    constructor(props) {
        super(props)
        this.passLoadingStage = this.passLoadingStage.bind(this);
    }

    passLoadingStage() {
        this.props.hasComponentLoadedHandler("mainImage", true);
    }

    render() {
        return (
            <div>
                <Fade
                    in={!this.props.componentsLoadingCompleted.mainImage}
                    unmountOnExit
                >
                    <div className="main-image-placeholder">
                        <CircularProgress />
                    </div>
                </Fade>
                <Tooltip
                    title="Ver imagen a tamaño completo"
                >

                    <img
                        onClick={this.props.mainImageClickHandler}
                        onLoad={this.passLoadingStage}
                        src={this.props.responsiveImageProperties.src} 
                        srcSet={this.props.responsiveImageProperties.srcSet}
                        alt={this.props.area}
                        className="img-responsive"
                    />


                </Tooltip>


            </div>

        )
    }


}

export default mainImage     