// App
//  |---Galería abarca todos los componentes del día, como...
// - Imagen o vdiaeo principal
// - Botón para mostrar más
// - Galeria del día
// - Display de texto del día
//  |---Mapa
//  |---Menu
//  |---Navegación de día

import React from "react";
import { BrowserRouter as Route, Switch, Link } from "react-router-dom";

import ExtraInfo from "./extraInfo";
import BotonExtraInfo from "./botonExtraInfo"
import BotonExtraFotos from "./botonExtraFotos"
import SubGaleria from "./subgaleria";
import SpotInfo from "./spotInfo"
import BotonSpotInfo from "./botonSpotInfo"
import SwipeGallery from "./swipeGallery"
import NavDiaActual from "./navDiaActual"
import NavFotos from "./navFotos"
import MainNav from "./mainNav"
import { browserHistory } from "react-router";
import VideoPlayer from "./videoPlayer"
import ReactAudioPlayer from "react-audio-player";
import Fullscreen from "react-full-screen";
import FullScreenButton from "./fullScreenButton"
import Tooltip from "@material-ui/core/Tooltip";
import MainImage from "./mainImage"
import ImageModal from "./imageModal"
import MediaControls from "./mediaControls"
import LeadsButton from "./leadsButton"
import { Helmet } from "react-helmet";
import DestinationIntro from "./destinationIntro";



class Galeria extends React.Component {

  constructor(props) {
    super(props);
    this.keyNavigation = this.keyNavigation.bind(this);
    this.escFunction = this.escFunction.bind(this);

    //handle ESC keypress in fullscreen
    document.addEventListener('fullscreenchange', this.escFunction);
    document.addEventListener('webkitfullscreenchange', this.escFunction);
    document.addEventListener('mozfullscreenchange', this.escFunction);
    document.addEventListener('MSFullscreenChange', this.escFunction);

  }

  //handle ESC keypress in fullscreen
  escFunction(event) {
    if (this.props.isFull && !document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.props.goFull(event, false)
    }
  }
  keyNavigation(event) {
    const diaActual = this.encontrarDias(this.props.countryTrips.daysArray);
    if (!this.props.isModalOpen) {
     // this.props.dayChangeHandler("dia");
      //down, left arrow, or enter...
      if ((event.keyCode === 37 || event.keyCode === 40) && diaActual.dia - 1 > 0) {
        this.props.history.push(`/galeria/${this.props.currentDestination}/${diaActual.dia - 1}`)
      }

      //up, right arrow, or enter...

      if ((event.keyCode === 39 || event.keyCode === 38 || event.keyCode === 13) && diaActual.dia < this.props.countryTrips.daysArray.length) {
        this.props.history.push(`/galeria/${this.props.currentDestination}/${diaActual.dia + 1}`)
      }
     // this.props.dayChangeHandler("dia")
    }
  }
 
  componentDidMount() {
    document.addEventListener("keydown", this.keyNavigation, false);


    //handle ESC pressed in fullscreen
    document.addEventListener("keydown", this.escFunction, false);

  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyNavigation, false);

    //handle ESC pressed in fullscreen
    document.removeEventListener("keydown", this.escFunction, false);

  }


  encontrarDias = () => {
    const encontrarDias = dia => (this.props.countryTrips.daysArray).find(day => day.dia == dia);
    return encontrarDias(this.props.match.params.dia)
  }
  

  encontrarSpot = (subgaleria) => {
    const infoSpotURL = this.props.match.params.infoSpot
    const isSpotURL = infoSpotURL && infoSpotURL !== 'info';
    if(isSpotURL){
      const activeSpot= subgaleria.find(element=>{
         return element.spotURL == infoSpotURL
      })
      return activeSpot;
    } else{
      return subgaleria[0];
    }
  }

  returnImageOrVideo = (spotActual) => {

    return (spotActual.mediaType === "video")
      ?
      <VideoPlayer
        hasComponentLoadedHandler={this.props.hasComponentLoadedHandler.bind(this)}
        componentsLoadingCompleted={this.props.componentsLoadingCompleted}
        videoSRC={spotActual.src}
        muted={this.props.muted}
        paused={this.props.paused}
      />
      :

      <div>
        <MainImage
          hasComponentLoadedHandler={this.props.hasComponentLoadedHandler.bind(this)}
          componentsLoadingCompleted={this.props.componentsLoadingCompleted}
          src={spotActual.src}
          responsiveImageProperties={spotActual.responsiveImageProperties}
          spot={spotActual.spot}
          audioAvailable={true}
          mainImageClickHandler={this.props.mainImageClickHandler}
          className="img-responsive" />

        {/*(spotActual.audioSRC !== null ?
          <ReactAudioPlayer
            src={spotActual.audioSRC}
            autoPlay={!this.props.paused}
            muted={this.props.muted}
            controls={false}
            controlsList={"nodownload noremoteplayback noprogress"}
            loop={true}
          />
        : null)*/}
      </div>
  }

  returnIsButtonDisabled = (spotActual) => {
    if (spotActual.info === null) {
      return true
    } else {
      return false
    }
  }

  render() {

    console.log(this.props)
    const diaActual = this.encontrarDias(this.props.countryTrips.daysArray);    
    const spotActual = this.encontrarSpot(diaActual.subgaleria);


    return (
      <div id="galeria"
        style={{ "height": this.props.isMobileOrPortrait ? this.props.windowInnerHeight : "100vh" }}
      >
        <Helmet>
          {
            typeof diaActual.frontmatter !== 'undefined' && diaActual.frontmatter.meta !== (null) ?
              <meta name="description" content={diaActual.frontmatter.meta} />
              :
              null
          }
        </Helmet>
        <Fullscreen
          enabled={this.props.isFull}
        >
        {spotActual.mediaType === 'image'?
           <ImageModal
           responsiveImageProperties={spotActual.responsiveImageProperties}
           isModalOpen={this.props.isModalOpen}
           handleModalClose={this.props.handleModalClose}
           src={spotActual.src}
           spot={spotActual.spot}
         />
         :
         null
        }
     

       
      {diaActual.dayInArea === 0 && this.props.navigatingForwards?
       <DestinationIntro
        firstDayInCountry={diaActual.dia === 1}
        country={this.props.currentDestination}
        isMobileOrPortrait={this.props.isMobileOrPortrait}
        coverImages={this.props.countryTrips.coverImages}
        area={diaActual.area}
        info={diaActual.info.slice(0, 58) + "..."}
       ></DestinationIntro>:
       null 
        
       }
          <Tooltip title={this.props.isFull ? "Salir de pantalla completa" : "Pantalla completa"}>
            <button className={"fullscreenbutton"} onClick={e => this.props.goFull(e, false)}>
              <FullScreenButton />
            </button>
          </Tooltip>
 



         {spotActual.mediaType === "video"
            ||
            spotActual.mediaType === "imageAudio"
            ?
            <MediaControls
              title={
                spotActual.mediaType === "video" ?
                  `Video: ${spotActual.spot}` :
                  `Audio: ${spotActual.audioName}`
              }
              muted={this.props.muted}
              pauseButtonHandler={this.props.pauseButtonHandler}
              muteButtonHandler={this.props.muteButtonHandler}
              mediaType={spotActual.mediaType}
              paused={this.props.paused}
            />
            : null
          }
         <MainNav
            page={'gallery'}
            countryTrips={this.props.countryTrips}
            country={this.props.currentDestination}
            trips={this.props.trips}
            linkToGalleryHandler={this.props.linkToGalleryHandler.bind(this)}
            isCountryDialogOpen={this.props.isCountryDialogOpen}
            handleCountryDialogClose={this.props.handleCountryDialogClose.bind(this)}
            handleCountryDialogOpen={this.props.handleCountryDialogOpen}
            handleCountryDropdownToggle={this.props.handleCountryDropdownToggle}
            handleCountryDropdownClose={this.props.handleCountryDropdownClose.bind(this)}
            dropdownAnchorEl={this.props.dropdownAnchorEl}
            isCountryDropdownOpen={this.props.isCountryDropdownOpen}
          />

 
          <SwipeGallery
            history={this.props.history}
            params={this.props.match.params}
            hasComponentLoadedHandler={this.props.hasComponentLoadedHandler}
            touchScreen={this.props.touchScreen}
            touchScreenDetectionHandler={this.props.touchScreenDetectionHandler}
            subgaleria={diaActual.subgaleria}
            selectedImageID={this.props.selectedImageID}
            spotActual={spotActual}
            onSwipeImageHandler={this.props.onSwipeImageHandler.bind(this)}
            isExtraImageSelected={this.props.isExtraImageSelected}
          >
{this.returnImageOrVideo(spotActual)}
          </SwipeGallery>
          {
            (this.props.touchScreen && !this.props.isMobileOrPortrait) || this.props.isTabletLandscape ?
              <NavFotos
                history={this.props.history}
                params={this.props.match.params}
                hasComponentLoadedHandler={this.props.hasComponentLoadedHandler}
                touchScreen={this.props.touchScreen}
                touchScreenDetectionHandler={this.props.touchScreenDetectionHandler}
                subgaleria={diaActual.subgaleria}
                selectedImageID={this.props.selectedImageID}
                selectedImageIndex={this.props.selectedImageIndex}
         
                onSwipeImageHandler={this.props.onSwipeImageHandler.bind(this)}
                isExtraImageSelected={this.props.isExtraImageSelected}
              >{this.returnImageOrVideo(diaActual, this.props.selectedImageIndex)}</NavFotos> :
              null
          }

          <header>

            {
              

     <NavDiaActual
              destino={this.props.currentDestination}
              dayChangeHandler={this.props.dayChangeHandler.bind(this)}
              diaActual={diaActual}
              DAYS={this.props.countryTrips.daysArray}
              touchScreen={false}
            />
              
            }
       
            <div className={"button-wrapper"} >
              <BotonExtraInfo
                area={diaActual.area}
                showExtraInfo={this.props.showExtraInfo}
                buttonExtraInfoClickHandler={this.props.buttonExtraInfoClickHandler}
                url={this.props.match.url} 
                params={this.props.match.params}>+INFO</BotonExtraInfo>
              <BotonSpotInfo
                params={this.props.match.params}
                isButtonDisabled={this.returnIsButtonDisabled(spotActual)}
                spotIcon={spotActual.type}
                spot={spotActual.spot}
                spotURL={spotActual.spotURL}
                showSpotInfo={this.props.showSpotInfo}
                buttonSpotClickHandler={this.props.buttonSpotClickHandler}
                url={this.props.match.url} />
            </div>
    
            {(this.props.showExtraInfo) && diaActual.frontmatter ?
              <ExtraInfo
                isFirstDayInArea={diaActual.dayInArea===0}
                numeroDia={diaActual.dia}
                area={diaActual.area}
                infoDia={diaActual.frontmatter.infoDia}
                descripcionArea={diaActual.info}>
              </ExtraInfo>
              :
              null
            }
            {this.props.showSpotInfo && !this.returnIsButtonDisabled(spotActual) ?
              <SpotInfo
                info={spotActual.info}></SpotInfo>
              :
              null
            }
            {!this.props.isTabletLandscape || this.props.touchScreen
              ?
              <div>
                <BotonExtraFotos
                  showExtraFotos={this.props.showExtraFotos}
                  buttonFotosClickHandler={this.props.buttonFotosClickHandler}
                  url={this.props.match.url} >+FOTOS
              </BotonExtraFotos>

                {(this.props.showExtraFotos && !this.props.isMobileOrPortrait)
                  ?
                  <SubGaleria
                    params={this.props.match.params}
                    destino={this.props.currentDestination}
                    showSpotInfo={this.props.showSpotInfo}
                    isExtraImageSelected={this.props.isExtraImageSelected}
                    subGaleriaClickHandler={this.props.subGaleriaClickHandler.bind(this)}
                    subgaleria={diaActual.subgaleria}
                    spotActual={spotActual}
                    numeroDia={diaActual.dia}
                    selectedImageIndex={this.props.selectedImageIndex}
                    selectedImageID={this.props.selectedImageID}>
                  </SubGaleria>
                  :
                  null}
              </div>
              :
              null
            }
          </header>
         {/*<LeadsButton></LeadsButton> */} 
        

        </Fullscreen>
      </div>
    )
  }
}


export default Galeria;
