import React from 'react';
import "../../../node_modules/video-react/dist/video-react.css"; // import css

import { Player, BigPlayButton, ControlBar  } from 'video-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.passLoadingStage = this.passLoadingStage.bind(this);
  }

  passLoadingStage() {
    this.props.hasComponentLoadedHandler("videoPlayer", true);
  }

  render() {
    return (
      <div>
        <Fade
          in={this.props.componentsLoadingCompleted.mainImage}
          unmountOnExit
        >
          <div className="main-image-placeholder">
            <CircularProgress />
          </div>
        </Fade>
        <Player
        autoPlay={!this.props.paused}
        playsInline
        src={this.props.videoSRC}
        muted={this.props.muted}
        onLoadStart={this.passLoadingStage}
      >
        <ControlBar disableCompletely={true} />
        <BigPlayButton position={"center"} style={{ display: "none !important" }} />

      </Player>
      </div>
      
    );
    
  }
  

}



export default VideoPlayer