import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';


class NavFotos extends React.Component {

    onSwipeImageHandler(direction, event) {
        const params = this.props.params;
        const subgallery = this.props.subgaleria;
        const selectedImageIndex = this.props.selectedImageIndex;
        //this will update loading stage in App state
        this.props.subgaleria.map((element, index) => {
            if (direction === "forwards" && index === this.props.selectedImageIndex + 1) {
                this.props.hasComponentLoadedHandler("videoPlayer", false);
                this.props.hasComponentLoadedHandler("mainImage", false);
                let swipeURL = `/galeria/${params.destino}/${params.dia}/${subgallery[selectedImageIndex+1].spotURL}`;
                this.props.history.push(swipeURL);

                return this.props.onSwipeImageHandler(element, "forwards");
            } else if (direction === "backwards" && index === this.props.selectedImageIndex - 1) {
                this.props.hasComponentLoadedHandler("videoPlayer", false);
                this.props.hasComponentLoadedHandler("mainImage", false);
                let swipeURL = `/galeria/${params.destino}/${params.dia}/${subgallery[selectedImageIndex-1].spotURL}`;
                this.props.history.push(swipeURL);
                return this.props.onSwipeImageHandler(element, "backwards");
            }
        });
    }

    render() {
        return (
            <div className={
                `nav ${this.props.touchScreen ? "touch" : ""}`
            }>
                <h2>
                    {
                        //in case no image is selected
                        (!this.props.isExtraImageSelected
                            &&
                            this.props.selectedImageIndex !== 1)
                            ||
                            //selected image is index 0
                            (
                                this.props.selectedImageIndex === 0
                            )
                            ?
                            <Button
                                disabled={true}
                                className="button-style"
                            >
                                <FontAwesomeIcon
                                    icon="angle-left"
                                />
                            </Button>
                            :

                            <Button
                                onClick={this.onSwipeImageHandler.bind(this, "backwards")}
                                className="button-style"
                            >
                                <FontAwesomeIcon
                                    icon="angle-left"
                                    className={"icon-body"}
                                />
                                <FontAwesomeIcon
                                    icon="angle-left"
                                    className={"icon-shadow"}
                                />
                            </Button>
                    }

                    {
                        //if it is the last image
                        this.props.selectedImageIndex === this.props.subgaleria.length-1

                            ?
                            <Button
                            disabled={true}
                            className="button-style"
                        >
                            <FontAwesomeIcon
                                icon="angle-right"
                            />
                        </Button>
                            :
                            <Button
                                onClick={this.onSwipeImageHandler.bind(this, "forwards")}
                                className="button-style">
                                <FontAwesomeIcon
                                    icon="angle-right"
                                    className={"icon-body"}
                                />
                                <FontAwesomeIcon
                                    icon="angle-right"
                                    className={"icon-shadow"}
                                />
                            </Button>
                          
                    }
                </h2>

            </div>
        )
    }
}



export default NavFotos