import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';


const NavDiaActual = (props, params) => {
    return (
        <div className={
            `nav ${props.touchScreen ? "touch" : ""}`
            }>
            <h2>
                {(props.diaActual.dia - 1 > 0)
                    ?
                    <Button
                        onClick={props.dayChangeHandler.bind(this,false)}
                        component={Link}
                        to={`/galeria/${props.destino}/${props.diaActual.dia - 1}`}
                        className="button-style"
                    >
                        <FontAwesomeIcon
                            icon="angle-left"
                            className={"icon-body"}
                        />
                        <FontAwesomeIcon
                            icon="angle-left"
                            className={"icon-shadow"}
                        />
                    </Button>
                    :
                    <Button
                        disabled={true}
                        className="button-style"
                    >
                        <FontAwesomeIcon
                            icon="angle-left"
                        />
                    </Button>
                }

                {(props.touchScreen ?
                    null :
                    <span className={"dia"}>DÍA {props.diaActual.dia}</span>

                )}

                {(props.diaActual.dia < props.DAYS.length)
                    ?
                    <Button
                        onClick={props.dayChangeHandler.bind(this,true)}
                        component={Link}
                        to={`/galeria/${props.destino}/${props.diaActual.dia + 1}`}
                        className="button-style">
                        <FontAwesomeIcon
                            icon="angle-right"
                            className={"icon-body"}
                        />
                        <FontAwesomeIcon
                            icon="angle-right"
                            className={"icon-shadow"}
                        />
                    </Button>
                    :
                    <Button
                        disabled={true}
                        className="button-style"
                    >
                        <FontAwesomeIcon
                            icon="angle-right"
                        />
                    </Button>
                }
            </h2>
           {/* {(props.touchScreen ?
                null :
                <h2>
                    {props.diaActual.titulo}
                </h2>
           )} */}
        </div>
    )

}
export default NavDiaActual