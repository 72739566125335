import React from 'react'
import Modal from '@material-ui/core/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageModal = (props)=>{
    return(     
        <Modal 
        className={"image-modal"}
        disablePortal={true}
        open={props.isModalOpen} 
        onClose={props.handleModalClose}
        aria-labelledby={props.spot}
      
       >
       {
            <div className={"wrapper"}  onClick={props.handleModalClose}>
       <div className={"info"} >
        <h3>{props.spot} <button
            aria-label="Close"
            className={"button-style"}          
            >
            <FontAwesomeIcon icon="times" />
            </button></h3>
            
        </div>
            <img     
                src={props.src} 
                srcSet={props.responsiveImageProperties.srcSet}    
                alt={props.spot}
            />
            </div>            
        }
        </Modal>
    )
}

export default ImageModal