import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button';


const BotonExtraInfo = (props) => {

    return ( 
        <Button 
            className={props.showExtraInfo ? "active" : null}          
            id="boton-extra-info"
            onClick={props.buttonExtraInfoClickHandler} >
            <Link to={`/galeria/${props.params.destino}/${props.params.dia}/${props.params.infoSpot && props.params.spot?props.params.infoSpot:(props.showExtraInfo?'':'info')}${props.params.spot?('/'+props.params.spot):''}`}>

            <FontAwesomeIcon icon="city" />
            <span>
                {" "}
                {props.area} {props.showExtraInfo ? "-" : "+"}
            </span>
            </Link>
        </Button>

    )
}

export default BotonExtraInfo;




