import React from "react";

import MainNav from "../gallery/mainNav";
import LinkToGallery from "../gallery/linkToGallery";
import InfoModal from "../gallery/infoModal";
import { Helmet } from "react-helmet";
class AppUserCreated extends React.Component {
  render() {
    return (
      <div className={"privacy"}>
        <Helmet>
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
      
      <h1>¡Has confirmado el usuario! </h1>
      <p>Vuelve a la app para comenzar.</p>

      </div>
    );
  }
}

export default AppUserCreated;
