import React from "react"
import VideoThumbnail from 'react-video-thumbnail'; // use npm published version

const Thumbnail = (props) =>{
    return(
        <VideoThumbnail
    videoUrl={props.videoSRC}
    thumbnailHandler={(thumbnail) => console.log(thumbnail)}
    width={60}
    snapshotAtTime={0}
    renderThumbnail={false}	
    />


    )
}

    



export default Thumbnail

