import React, { useState, useEffect } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@material-ui/core/Collapse';

import ReactMarkdown from 'react-markdown'


const ExtraInfo = ((props)=>{ 


    //reset scroll
    useEffect(() => { 
      const scrollbar = document.querySelectorAll('.scrollbar>div');
      if(scrollbar.length > 0){
        scrollbar[0].scrollTop = 0;     
      }
    });

    //toggle dropdown
    const [showAreaInfo, toggleAreaInfo] = useState(props.isFirstDayInArea);

    //https://stackoverflow.com/questions/54865764/react-usestate-does-not-reload-state-from-props
    //calls the effect on prop change
    useEffect(() => { 

      toggleAreaInfo((props.isFirstDayInArea === true)?true:false);
     
    }, [props.isFirstDayInArea]);

    function toggle() {

      toggleAreaInfo(!showAreaInfo);

    }

      return(   
     <Fade
        in={true}
        timeout={{enter: 200, exit:  200}}
        > 

        <article id="extra-info">  
        <Scrollbars className={"scrollbar"} style={{ width: 'auto', height: 250 }}>
        <div className="info-wrapper">
          <div className="info-dia">
          <h4>
          <FontAwesomeIcon icon="calendar-day" />
             DÍA {props.numeroDia} </h4>
          <h3>{props.infoDia} </h3>
          </div>
          <button className={`${showAreaInfo?'up':'down'}`} onClick={()=>toggle()}>                       
          <FontAwesomeIcon icon="info-circle" />
          <span>Sobre {props.area} </span><FontAwesomeIcon icon="angle-down" className={'dropdown-icon'} />
          </button>
            <Collapse
             
              in={showAreaInfo}
              timeout={400}
            >
                <ReactMarkdown className={`area-info`}>{props.descripcionArea}</ReactMarkdown>
            </Collapse>
        </div>
        </Scrollbars>
        </article> 
        </Fade>     
    )
    
  
  })

export default ExtraInfo;
    
    


