import React from "react";
import { BrowserRouter as Route, Switch, Link } from "react-router-dom";

//import errorImg from './assets/error404.jpg';
//console.log(errorImg);
const Error404 = (props) => {
    return (

        <div>
              Error 404, la página se fue de viaje <br/>
              <Link to="/home">Iniciar sesión</Link> <br/>
              <Link to="/home/viajes">Ver viajes sin iniciar sesión</Link>
        </div>
    )
}

export default Error404;




