import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BrowserRouter as Route, Switch, Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

function returnTypeIcon(spotIcon){
    let icon;
    switch (spotIcon){
        
        case "top":
        icon= "camera"
        break

        case "comida":
        icon="utensils"
        break

        default:
        icon="map-marker-alt" 

    }
    return <FontAwesomeIcon icon={icon} /> 
}

const BotonSpotInfo = ({...props}) =>{

    const params = props.params;
    let linkTo = `/galeria/${params.destino}/${params.dia}/${props.spotURL}`;
    
      
    return(     
        <Tooltip
        title={props.isButtonDisabled?"Foto sin descripción":""}
        placement={"top"}
        enterTouchDelay={0}
        >  
        <span className="boton-spot-info-wrapper">
        <Button 
        disabled={props.isButtonDisabled}   
        onClick={props.buttonSpotClickHandler} 
        color={"inherit"}
        className={props.showSpotInfo && !props.isButtonDisabled ? "active" : null}
        id="boton-spot-info"
        >
        <Link to={linkTo}>
        {returnTypeIcon(props.spotIcon)}
        
        <span>
                {" "}
                {props.spot} {!props.isButtonDisabled?(props.showSpotInfo ? "-" : "+"):""}
            </span>   
            </Link>    
        </Button>
        </span>
        </Tooltip>

    )
}

export default BotonSpotInfo;
    
    


